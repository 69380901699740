import React, { useState, useEffect } from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import { Typography, Avatar, Grid, InputLabel, MenuItem, FormControl, Select, List, ListItem, ListItemIcon, ListItemText, Divider, ListItemAvatar, Chip, ListItemButton, CircularProgress, IconButton, Menu } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent";
import styled from "@emotion/styled";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { capitalizeFLetter } from "@jumbo/utils";
import Div from "@jumbo/shared/Div";
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';
import { config } from '../../config/main';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';

const ListItemInline = styled(ListItem)(({ theme }) => ({
    width: 'auto',
    display: 'inline-flex',
    padding: theme.spacing(0, .5),
}));

const DriverDetails = () => {
    const path = useLocation();
    const queryParams = new URLSearchParams(path.search);
    const id = queryParams.get('id');
    const [shift, setShift] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [driverData, setDriverData] = useState({})
    const [shiftsData, setShiftsData] = useState([])
    const [recentlyParked, setRecentlyParked] = useState([])
    const [recentlyDelivered, setRecentlyDelivered] = useState([])
    const [graphData, setGraphData] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [downloading, setDownloading] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const getShiftsData = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/location_admin/driver_shifts?id=${id}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setShiftsData(data.shiftsData ? data.shiftsData.shift_ids : [])
                setDriverData(data.shiftsData ? data.shiftsData.driver_id : {})
                setShift(data.shiftsData && data.shiftsData.shift_ids && data.shiftsData.shift_ids.length > 0 ? data.shiftsData.shift_ids[0]._id : '')
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (id) {
            getShiftsData()
        }
    }, [id])

    const getDriverTripDetails = async (shift_id) => {
        setLoadingData(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/location_admin/driver_trips?shift_id=${shift_id}&id=${id}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setRecentlyParked(data.recentlyParked || [])
                setRecentlyDelivered(data.recentlyDelivered || [])
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoadingData(false)
    }

    const getDriverGraphData = async (shift_id) => {
        setLoadingData(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/location_admin/driver_graph?shift_id=${shift_id}&id=${id}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                const arr = []
                const monthData = data.graphData || []
                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                for (const [i] of Array(12).entries()) {
                    const d = monthData.filter(v => Number(v._id) === Number(i + 1))
                    arr.push({ month: months[i], parked: d && d[0] ? d[0].parked : 0, delivered: d && d[0] ? d[0].delivered : 0 })
                }
                setGraphData(arr)
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoadingData(false)
    }

    useEffect(() => {
        if (shift) {
            getDriverTripDetails(shift)
            getDriverGraphData(shift)
        } else {
            setRecentlyDelivered([])
            setRecentlyParked([])
            setGraphData([])
        }
    }, [shift])

    const download = async (url, type) => {
        setDownloading(true);
        try {
            if (url) {
                const link = document.createElement('a');
                link.href = url;
                link.download = type;
                link.target = "_blank"
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error(`${type} not found`)
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setDownloading(false)
    }

    return (
        <>
            <PageHeader
                title={<Typography fontSize={"30px"} variant="h6" color="text.primary" mb={.5}>Driver details</Typography>}
                subheader={<Typography marginTop={4}>
                    {
                        !loading &&
                        <Grid container spacing={2} mb={1} sm={6}>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="shift">Shift</InputLabel>
                                    <Select
                                        label={"Shift"}
                                        value={shift}
                                        small={"large"}
                                        fullWidth
                                        onChange={(e) => setShift(e.target.value)}
                                    >
                                        <MenuItem value="">Shift</MenuItem>
                                        {
                                            shiftsData && shiftsData.length > 0 && shiftsData.map((val, key) => {
                                                return <MenuItem key={key} value={val._id}>{val.title}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                </Typography>}
            />
            {
                loading ?
                    <div className="loader-view" style={{ height: 'calc(100vh - 259px)' }}>
                        <CircularProgress />
                    </div>
                    :
                    <Grid container spacing={3.75}>
                        <Grid item xs={12} md={4} xl={3}>
                            <JumboCardQuick noWrapper>
                                <JumboContent
                                    sx={{
                                        p: theme => theme.spacing(3, 3),
                                        textAlign: 'center'
                                    }}
                                >
                                    <div style={{ textAlign: 'end' }}>
                                        <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                            aria-label="Open to show more"
                                            title="Open to show more"
                                        >
                                            <DownloadIcon color='primary' />
                                        </IconButton>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={() => setAnchorEl(null)}
                                        >
                                            <MenuItem onClick={() => download(driverData && driverData.driving_license_img ? driverData.driving_license_img : '', 'Driving Licence')}>
                                                Driving Licence
                                            </MenuItem>
                                            <MenuItem onClick={() => download(driverData && driverData.aadhar_img ? driverData.aadhar_img : '', 'Aadhar')}>
                                                Aadhar
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                    <Avatar sx={{ width: 90, height: 90, boxShadow: 2, m: '0 auto 20px', fontSize: '50px' }}>{driverData && driverData.name ? driverData.name[0] : ''}</Avatar>
                                    <Typography variant={"h5"}>{driverData ? driverData.name : ''}</Typography>
                                </JumboContent>
                                <JumboContent
                                    sx={{
                                        p: 3,
                                        textAlign: 'center',
                                        backgroundColor: theme => theme.palette.action.hover
                                    }}
                                >
                                    <List disablePadding>
                                        <ListItem sx={{ p: theme => theme.spacing(1.5, 3) }}>
                                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                                                <PhoneIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={driverData ? driverData.phone_no : ''} />
                                        </ListItem>
                                        <Divider component="li" />
                                        <ListItem sx={{ p: theme => theme.spacing(1.5, 3) }}>
                                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                                                <MailOutlineIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={driverData && driverData.email ? driverData.email : 'NA'} />
                                        </ListItem>
                                    </List>
                                </JumboContent>
                            </JumboCardQuick>
                        </Grid>
                        <Grid item xs={12} md={8} xl={9}>
                            <JumboCardQuick
                                title="Parked Vs Delivered"
                                subheader="This year"
                                action={
                                    <List disablePadding>
                                        <ListItemInline>
                                            <ListItemIcon sx={{ minWidth: 16 }}>
                                                <FiberManualRecordIcon fontSize={"10px"} sx={{ color: "#1E88E5" }} />
                                            </ListItemIcon>
                                            <ListItemText secondary="Parked" />
                                        </ListItemInline>
                                        <ListItemInline>
                                            <ListItemIcon sx={{ minWidth: 16 }}>
                                                <FiberManualRecordIcon fontSize={"10px"} sx={{ color: "#E91E63" }} />
                                            </ListItemIcon>
                                            <ListItemText secondary="Delivered" />
                                        </ListItemInline>
                                    </List>
                                }
                                sx={{
                                    '& .MuiCardHeader-action': {
                                        alignSelf: 'flex-end',
                                        mb: -1
                                    }
                                }}
                                wrapperSx={{ pt: 3.75 }}
                            >
                                <ResponsiveContainer width="100%" height={200}>
                                    {loadingData ?
                                        <div className="loader-view">
                                            <CircularProgress />
                                        </div>
                                        :
                                        !graphData || graphData.length < 1 ?
                                            <div className="loader-view">
                                                Sorry, no data found
                                            </div>
                                            :
                                            <BarChart data={graphData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                <Tooltip
                                                    animationEasing={"ease-in-out"}
                                                    content={({ active, label, payload }) => {
                                                        return active && payload ? (
                                                            <Div sx={{ color: "common.white" }}>
                                                                {payload.map((row, index) => {
                                                                    return (
                                                                        <div key={index} className={index !== payload.length - 1 ? "mb-1" : ""}>
                                                                            <div style={{
                                                                                color: row.color,
                                                                                fontSize: 8,
                                                                                letterSpacing: 2,
                                                                                textTransform: 'uppercase'
                                                                            }}>
                                                                                {capitalizeFLetter(row.name)}
                                                                            </div>
                                                                            <div style={{
                                                                                color: row.color
                                                                            }}
                                                                            >{row.value}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Div>
                                                        ) : null;
                                                    }}
                                                    wrapperStyle={{
                                                        background: 'rgba(255,255,255,0.9)',
                                                        borderRadius: 4,
                                                        padding: '5px 8px',
                                                        fontWeight: 500,
                                                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                                                    }}
                                                />

                                                <XAxis dataKey="month" />
                                                <Bar dataKey="parked" stackId="a" fill="#1E88E5" barSize={8} />
                                                <Bar dataKey="delivered" stackId="a" fill="#E91E63" barSize={8} />
                                            </BarChart>
                                    }
                                </ResponsiveContainer>
                            </JumboCardQuick>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <JumboCardQuick
                                title='Recently parked'
                                wrapperSx={{ p: 0 }}
                                headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }}
                            >
                                <JumboScrollbar
                                    autoHeight
                                    autoHeightMin={366}
                                    autoHide
                                    autoHideDuration={200}
                                    autoHideTimeout={500}
                                >
                                    {loadingData ?
                                        <div className="loader-view">
                                            <CircularProgress />
                                        </div>
                                        :
                                        !recentlyParked || recentlyParked.length < 1 ?
                                            <div className="loader-view" style={{ marginTop: '20px' }}>
                                                Sorry, no records found
                                            </div>
                                            :
                                            <List disablePadding>
                                                {
                                                    recentlyParked && recentlyParked.length > 0 && recentlyParked.map((trip, index) => (
                                                        <ListItemButton key={index} component={"li"} sx={{ p: theme => theme.spacing(1, 3), borderBottom: 1, borderBottomColor: 'divider' }}>
                                                            <ListItemAvatar sx={{ minWidth: 64 }}>
                                                                <Avatar alt={trip.car_id.name} src={trip.car_id.image} sx={{ width: '52px', height: '52px' }} />
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={<Typography variant='h5' mb={.5}>{trip.car_id.brand} {trip.car_id.model}</Typography>}
                                                                secondary={moment(trip.timeline.date_time).format("DD/MM/YYYY HH:mm")}
                                                            />
                                                            <ListItemText sx={{ flexGrow: 0 }}>
                                                                <Chip label={`${trip.car_id.plate_no}`} color="primary" size={'medium'} />
                                                            </ListItemText>
                                                        </ListItemButton>
                                                    ))
                                                }
                                            </List>
                                    }
                                </JumboScrollbar>
                            </JumboCardQuick>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <JumboCardQuick
                                title='Recently delivered'
                                wrapperSx={{ p: 0 }}
                                headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }}
                            >
                                <JumboScrollbar
                                    autoHeight
                                    autoHeightMin={366}
                                    autoHide
                                    autoHideDuration={200}
                                    autoHideTimeout={500}
                                >
                                    {loadingData ?
                                        <div className="loader-view">
                                            <CircularProgress />
                                        </div>
                                        :
                                        !recentlyDelivered || recentlyDelivered.length < 1 ?
                                            <div className="loader-view" style={{ marginTop: '20px' }}>
                                                Sorry, no records found
                                            </div>
                                            :
                                            <List disablePadding>
                                                {
                                                    recentlyDelivered && recentlyDelivered.length > 0 && recentlyDelivered.map((trip, index) => (
                                                        <ListItemButton key={index} component={"li"} sx={{ p: theme => theme.spacing(1, 3), borderBottom: 1, borderBottomColor: 'divider' }}>
                                                            <ListItemAvatar sx={{ minWidth: 64 }}>
                                                                <Avatar alt={trip.car_id.name} src={trip.car_id.image} sx={{ width: '52px', height: '52px' }} />
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={<Typography variant='h5' mb={.5}>{trip.car_id.brand} {trip.car_id.model}</Typography>}
                                                                secondary={moment(trip.timeline.date_time).format("DD/MM/YYYY HH:mm")}
                                                            />
                                                            <ListItemText sx={{ flexGrow: 0 }}>
                                                                <Chip label={`${trip.car_id.plate_no}`} color="primary" size={'medium'} />
                                                            </ListItemText>
                                                        </ListItemButton>
                                                    ))
                                                }
                                            </List>
                                    }
                                </JumboScrollbar>
                            </JumboCardQuick>
                        </Grid>
                    </Grid>
            }
        </>
    )
};

export default DriverDetails;
