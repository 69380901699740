import React from 'react';
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import { SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import List from "@mui/material/List";
import PropTypes from 'prop-types';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const JumboVerticalNavbar = ({ items }) => {
    const { sidebarOptions } = useJumboLayoutSidebar();
    const { userData } = useJumboAuth()

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    return (
        <List
            disablePadding
            sx={{
                mr: isMiniAndClosed ? 0 : 2,
                pb: 2,
                pt: 4,
            }}
        >
            {
                items.map((item, index) => (
                    (userData && userData.is_staff && userData.modules && userData.modules.includes(item.label.toLowerCase())) || item.label === 'Dashboard' ? <JumboNavIdentifier translate item={item} key={index} /> : userData && !userData.is_staff ? <JumboNavIdentifier translate item={item} key={index} /> : ''
                ))
            }
        </List>
    );
};

JumboVerticalNavbar.defaultProps = {
    items: PropTypes.array,
    translate: false
};

export default JumboVerticalNavbar;