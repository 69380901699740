import React, { useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../../config/main';
import DataTable from "../../shared/DataTable";
import './index.css';
import { Button, ButtonGroup, CircularProgress, Typography } from "@mui/material";
import AddShift from "./AddShift";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { toast } from "react-toastify";
import EditShift from "./EditShift";
import moment from "moment";
import AsignDrivers from "./AsignDrivers"
import DriversList from "./DriversList";
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';

const Shifts = () => {
    const { showDialog, hideDialog } = useJumboDialog();
    const [loading, setLoading] = useState(false)
    const [shiftsData, setShiftsData] = useState([])
    const [driversWithShifts, setDriversWithShift] = useState([])
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(1)
    const [searchedText, setSearchedText] = useState("")

    const columns = [
        {
            name: "title",
            label: "Title",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "start_time",
            label: "Start time",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "end_time",
            label: "End time",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "drivers",
            label: "Drivers",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];

    const options = {
        searchPlaceholder: 'Press Enter to Search',
        filterType: 'dropdown',
        responsive: 'scroll',
        viewColumns: false,
        rowsPerPage: tableData.length,
        page,
        rangePage: true,
        print: false,
        download: false,
        filter: false,
        search: true,
        sort: false,
        count: tableData.length,
        serverSide: true,
        server: true,
        selectableRowsOnClick: false,
        selectableRows: 'none',
        fixedHeader: false,
        searchText: searchedText,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    break;
                case 'changeRowsPerPage':
                    break;
                case 'search':
                    onkeypress = (e) => {
                        if (e.key == 'Enter') {
                            getShifts(tableState.searchText)
                            setSearchedText(tableState.searchText)
                        }
                    }
                    break;
                case 'onSearchClose':
                    setSearchedText("")
                    getShifts()
                    break;
                case 'onFilterDialogClose':
                    break
                case 'onFilterDialogOpen':
                    break;
                default:
                    break;
            }
        },
    }

    const getShifts = async (search) => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/shifts?search=${search}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setShiftsData(data.shiftsData || [])
                setDriversWithShift(data.driversWithShifts || [])
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    const refreshData = () => {
        hideDialog()
        getShifts()
    }

    const deleteShift = async (id) => {
        setLoading(true)
        try {
            const { data } = await axios.post(`${config.baseUrl}/shifts/delete`, { id }, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                toast.success("Shift deleted successfully!")
                refreshData()
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    const handleAddClick = () => {
        showDialog({
            title: 'Add shift',
            content: <AddShift refreshData={refreshData} />
        });
    }

    const handleEditClick = (data) => {
        showDialog({
            title: 'Edit shift',
            content: <EditShift refreshData={refreshData} shift={data} />
        });
    }

    const handleDeleteClick = (data) => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure about deleting this shift?',
            content: "You won't be able to recover this shift later.",
            onYes: () => deleteShift(data._id),
            onNo: hideDialog
        })
    }

    useEffect(() => {
        getShifts()
    }, [])

    const handleAsignDriversClick = (data) => {
        showDialog({
            title: `Asign drivers to shift ${data.title} `,
            content: <AsignDrivers refreshData={refreshData} shift={data} />,
            maxWidth: "md"
        });
    }

    const handleDriversClick = (data, drivers) => {
        showDialog({
            content: <DriversList hideDialog={hideDialog} data={data} drivers={drivers} />
        });
    }

    useEffect(() => {
        if (shiftsData && shiftsData.length > 0) {
            let data = []
            for (const val of shiftsData) {
                const driverData = driversWithShifts.filter(v => String(v._id) === String(val._id))
                const drivers = driverData && driverData[0] ? driverData[0].drivers : []
                data.push({
                    title: val.title,
                    start_time: moment.utc(val.start_time, "HH:mm").add(5, 'hours').add(30, 'minutes').format("hh:mm A"),
                    end_time: moment.utc(val.end_time, "HH:mm").add(5, 'hours').add(30, 'minutes').format("hh:mm A"),
                    drivers: <Button variant="text" sx={{ textDecoration: 'underline' }} onClick={() => handleDriversClick(val, drivers)}>{drivers.length}</Button>,
                    action: <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button variant="outlined" onClick={(e) => handleAsignDriversClick(val)} className={'text-warning'}>
                            Assign Drivers
                        </Button>
                        <Button variant="outlined" onClick={(e) => handleEditClick(val)} className={'text-warning'}>
                            Edit
                        </Button>
                        <Button variant="outlined" onClick={(e) => handleDeleteClick(val)} className={'text-success'}>
                            Delete
                        </Button>
                    </ButtonGroup>
                })
            }
            setTableData(data)
        } else {
            setTableData([])
        }
    }, [shiftsData, driversWithShifts])

    return (
        <React.Fragment>
            <PageHeader
                title={<Typography fontSize={"30px"} variant="h6" color="text.primary" mb={.5}>Shifts</Typography>}
            />
            {
                loading ?
                    <div className="loader-view" style={{ height: 'calc(100vh - 259px)' }}>
                        <CircularProgress />
                    </div>
                    :
                    <DataTable
                        title={<Button color='primary' variant="outlined" onClick={handleAddClick}> Add Shift +</Button>}
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
            }
        </React.Fragment>
    );
}

export default Shifts;