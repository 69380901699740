import React, { useState, useEffect } from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import axios from 'axios';
import { config } from 'app/config/main';
import { toast } from 'react-toastify';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const validationSchema = yup.object({
    name: yup.string().required('Title is required'),
    phone_no: yup.string().length(10, "Phone number must be 10 digit long").required('Phone number is required'),
    role: yup.string().required('Role is required'),
});
const initialValues = {
    name: "",
    phone_no: "",
    role: "",
    is_active: false,
}

const EditStaff = ({ staff, refreshData }) => {
    const [staffData, setStaffData] = useState({})

    const onStaffSave = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            values.id = staff._id
            const { data } = await axios.post(`${config.baseUrl}/staff/update`, values, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                toast.success("Staff updated successfully!")
                refreshData()
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setSubmitting(false);
    };

    useEffect(() => {
        if (staff && Object.keys(staff).length > 0) {
            setStaffData({
                name: staff.name || "",
                phone_no: staff.phone_no || "",
                role: staff.role || "",
                is_active: staff.is_active || false,
            })
        } else {
            setStaffData(initialValues)
        }
    }, [staff])

    return (
        <Formik
            validateOnChange={true}
            initialValues={staffData}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onStaffSave}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <JumboTextField fullWidth size="small" variant="outlined" name="name" label="Name" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="phone_no" label="Phone number" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="role" label="Role" />
                        <FormControlLabel control={<Switch name='is_active' checked={values.is_active} onChange={(e) => setFieldValue("is_active", e.target.checked)} />} label="Active" />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >Save</LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};

export default EditStaff;
