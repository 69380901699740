import React from 'react';
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { Avatar, ListItemAvatar, ListItemText, List } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";

const DriversList = ({ hideDialog, drivers, data }) => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12}>
                <JumboCardQuick
                    title={`Drivers for shift ${data.title}`}
                    wrapperSx={{ p: 0 }}
                    headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }}
                >
                    <JumboScrollbar
                        autoHeight
                        autoHeightMin={120}
                        autoHide
                        autoHideDuration={200}
                        autoHideTimeout={500}
                    >
                        <List disablePadding>
                            {
                                drivers.map((author, index) => (
                                    <ListItemButton component={"li"} sx={{ p: theme => theme.spacing(1, 3), borderBottom: 1, borderBottomColor: 'divider' }}>
                                        <ListItemAvatar sx={{ minWidth: 64 }}>
                                            <Avatar alt={author.name} src={author.profile_pic} sx={{ width: '52px', height: '52px' }} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography variant='h5' mb={.5}>{author.name}</Typography>}
                                        />
                                        <ListItemText
                                            primary={<Typography variant='h5' mb={.5}>{author.phone_no}</Typography>}
                                        />
                                    </ListItemButton>
                                ))
                            }
                        </List>
                    </JumboScrollbar>
                </JumboCardQuick>
            </Grid>
        </Grid>
    );
};

export default DriversList;