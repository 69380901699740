import React, { useState, useEffect } from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import axios from 'axios';
import { config } from 'app/config/main';
import { toast } from 'react-toastify';
import { IconButton, Switch, FormControlLabel } from '@mui/material';
import { UploadFile } from '@mui/icons-material';

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Enter a valid email'),
    phone_no: yup.string().length(10, "Phone number must be 10 digit long").required('Phone number is required'),
});
const initialValues = {
    name: "",
    phone_no: "",
    email: "",
    is_manager: false,
    aadhar_img: "",
    driving_license_img: "",
};

const EditDriver = ({ driver, refreshData }) => {
    const [driverData, setDriverData] = useState({})
    const [aadhar_img, setAadharImg] = useState("")
    const [driving_license_img, setDrivingLicenseImg] = useState("")

    const onDriverSave = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            if (!aadhar_img) {
                setSubmitting(false)
                return toast.error("Please select Aadhar image")
            }
            if (!driving_license_img) {
                setSubmitting(false)
                return toast.error("Please select Driving license image")
            }
            values.aadhar_img = aadhar_img
            values.driving_license_img = driving_license_img
            values.id = driver._id
            const { data } = await axios.post(`${config.baseUrl}/location_admin/update`, values, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                toast.success("Driver updated successfully!")
                refreshData()
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setSubmitting(false);
    };

    const onSelectFile = (e, type) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            if (type === "aadhar") {
                setAadharImg(reader.result)
            } else if (type === "driving") {
                setDrivingLicenseImg(reader.result)
            }
        };
        reader.readAsDataURL(files[0]);
    };

    useEffect(() => {
        if (driver && Object.keys(driver).length > 0) {
            setDriverData({
                name: driver.name || "",
                phone_no: driver.phone_no || "",
                email: driver.email || "",
                is_manager: driver.is_manager || false
            })
            setAadharImg(driver.aadhar_img)
            setDrivingLicenseImg(driver.driving_license_img)
        } else {
            setDriverData(initialValues)
        }
    }, [driver])

    return (
        <Formik
            validateOnChange={true}
            initialValues={driverData}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onDriverSave}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <JumboTextField fullWidth size="small" variant="outlined" name="name" label="Name" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="phone_no" label="Phone Number" type="number" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="email" label="Email" type="email" />
                        <FormControlLabel style={{ marginTop: "-10px" }} control={<Switch name='is_manager' checked={values.is_manager} onChange={(e) => setFieldValue("is_manager", e.target.checked)} />} label="Manager" />
                        <div style={{ marginLeft: "-10px" }}>
                            <input accept="image/*" id="aadhar" name='aadhar_img' style={{ display: 'none' }} onChange={(e) => onSelectFile(e, "aadhar")} type="file" />
                            <label htmlFor="aadhar">
                                <IconButton color={aadhar_img ? 'success' : 'secondary'} component="span">
                                    <UploadFile />
                                </IconButton>
                                Aadhar Image
                            </label>
                        </div>
                        <div style={{ marginLeft: "-10px", marginBottom: "10px" }}>
                            <input accept="image/*" name='driving_license_img' id="driving_license" style={{ display: 'none' }} onChange={(e) => onSelectFile(e, "driving")} type="file" />
                            <label htmlFor="driving_license">
                                <IconButton color={driving_license_img ? 'success' : 'secondary'} component="span">
                                    <UploadFile />
                                </IconButton>
                                Driving License Image
                            </label>
                        </div>
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >Save</LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};

export default EditDriver;
