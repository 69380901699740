import React, { useState } from "react"
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import LoadingButton from "@mui/lab/LoadingButton";

const CropImage = ({ setShowCrop, image, setImage, ratio }) => {
    const [cropper, setCropper] = useState("");

    const handleCropClose = () => {
        setCropper("");
        setShowCrop(false);
    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setImage(cropper.getCroppedCanvas().toDataURL());
            handleCropClose()
        }
    };

    return (
        <div >
            <div className="mb-3">
                <Cropper
                    style={{ height: 200, width: "100%" }}
                    zoomTo={0.5}
                    aspectRatio={ratio ? ratio : 1}
                    src={image}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    guides={true}
                />
            </div>
            <LoadingButton
                fullWidth
                type="button"
                onClick={getCropData}
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
            >Crop Image</LoadingButton>
        </div>
    )
}

export default CropImage