import React, { useEffect, useState } from 'react';
import { Grid, capitalize } from "@mui/material";
import Typography from "@mui/material/Typography";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, Bar, BarChart, XAxis } from 'recharts';
import Div from "@jumbo/shared/Div";
import { capitalizeFLetter } from "@jumbo/utils";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { Avatar, Chip, ListItemAvatar, ListItemText, List } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { toast } from "react-toastify";
import axios from 'axios';
import { config } from '../../config/main';

const data = [
    {
        name: 'Page A',
        profit: 5000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        profit: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        profit: -2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        profit: 2900,
        pv: 5308,
        amt: 2000,
    },
    {
        name: 'Page E',
        profit: -2500,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        profit: 1500,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        profit: 4000,
        pv: 4300,
        amt: 2100,
    },
];

const monthInitials = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']

const gradientOffset = () => {
    const dataMax = Math.max(...data.map((i) => i.profit));
    const dataMin = Math.min(...data.map((i) => i.profit));

    if (dataMax <= 0) {
        return 0;
    }
    if (dataMin >= 0) {
        return 1;
    }

    return dataMax / (dataMax - dataMin);
};

const off = gradientOffset();

const Dashboard = () => {
    const { userData } = useJumboAuth();
    const [trips, setTrips] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [monthlyTripsData, setMonthlyTripsData] = useState([{ 'J': 0 }, { 'F': 0 }, { 'M': 0 }, { 'A': 0 }, { 'M': 0 }, { 'J': 0 }, { 'J': 0 }, { 'A': 0 }, { 'S': 0 }, { 'O': 0 }, { 'N': 0 }, { 'D': 0 }])
    const [totalTrips, setTotalTrips] = useState(0)
    const [loading, setLoading] = useState(false);

    const getDashboardData = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/location_admin/dashboard_data`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setTrips(data.activeTrips || [])
                setDrivers(data.activeDrivers || [])
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    const getDashboardGraphData = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/location_admin/dashboard_graph_data`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                const monthlyTrips = data.monthlyTrips || [];
                const formattedMonthlyTrips = [];
                let total = 0
                for (const [i] of monthInitials.entries()) {
                    let monthData = monthlyTrips.filter(v => (Number(v._id) - 1) === i)
                    formattedMonthlyTrips.push({
                        month: monthInitials[i],
                        trips: monthData && monthData[0] && monthData[0].total ? monthData[0].total : 0
                    })
                    total += monthData && monthData[0] && monthData[0].total ? monthData[0].total : 0
                }
                setMonthlyTripsData(formattedMonthlyTrips)
                setTotalTrips(total)
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    useEffect(() => {
        getDashboardData()
        getDashboardGraphData()
    }, [])

    return (
        <>
            <PageHeader
                title={<Typography fontSize={"30px"} variant="h6" color="text.primary" mb={.5}>Welcome to {userData.location_title}</Typography>}
            />
            <Grid container spacing={3.75}>
                <Grid item xs={12} md={6}>
                    <JumboCardQuick
                        title={<Typography variant={"h4"} mb={0}>Trips per month for current year</Typography>}
                        wrapperSx={{ pt: 0 }}
                    >
                        <Grid container columnSpacing={2}>
                            <Grid item xs={5} alignSelf={"center"}>
                                <Typography variant={"h2"}>{totalTrips}</Typography>
                                <Typography variant={"body1"} color={"text.secondary"} sx={{ whiteSpace: 'nowrap' }} mb={0}>Total trips</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Div sx={{ my: '-2px' }}>
                                    <ResponsiveContainer height={100}>
                                        <BarChart data={monthlyTripsData}>
                                            <Tooltip
                                                animationEasing={"ease-in-out"}
                                                content={({ active, label, payload }) => {
                                                    return active ? (
                                                        <Div sx={{ color: "common.white" }}>
                                                            {payload.map((row, index) => {
                                                                return (
                                                                    <div key={index} className={index !== payload.length - 1 ? "mb-1" : ""}>
                                                                        <div style={{
                                                                            color: row.color,
                                                                            fontSize: 8,
                                                                            letterSpacing: 2,
                                                                            textTransform: 'uppercase'
                                                                        }}>
                                                                            {capitalizeFLetter(row.name)}
                                                                        </div>
                                                                        <div style={{
                                                                            color: row.color
                                                                        }}
                                                                        >{row.value}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </Div>
                                                    ) : null;
                                                }}
                                                wrapperStyle={{
                                                    background: 'rgba(255,255,255,0.9)',
                                                    borderRadius: 4,
                                                    padding: '5px 8px',
                                                    fontWeight: 500,
                                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                                                }}
                                            />
                                            <XAxis dataKey="month" tickLine={false} axisLine={false} />
                                            <Bar dataKey="trips" fill="#9046EB" stackId={"a"} maxBarSize={10} barSize={4} />
                                            {/* <Bar dataKey="past" fill="#7B92D8" stackId={"a"} maxBarSize={10} barSize={4} /> */}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Div>
                            </Grid>
                        </Grid>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={12} md={6}>
                    <JumboCardQuick
                        title={<Typography variant={"h4"} mb={0}>Parked vs Delivered per location per quarter</Typography>}
                        wrapperSx={{ pt: 0 }}
                    >
                        <Grid container columnSpacing={2}>
                            <Grid item xs={8}>
                                <ResponsiveContainer height={100}>

                                    <AreaChart
                                        width={500}
                                        height={400}
                                        data={data}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="6 1 2" horizontal={false} strokeOpacity={0.7} />
                                        <Tooltip
                                            animationEasing={"ease-in-out"}
                                            content={({ active, label, payload }) => {
                                                return active ? (
                                                    <Div sx={{ color: "common.white" }}>
                                                        {payload.map((row, index) => {
                                                            return (
                                                                <div key={index} className={index !== payload.length - 1 ? "mb-1" : ""}>
                                                                    <div style={{
                                                                        color: row.color,
                                                                        fontSize: 8,
                                                                        letterSpacing: 2,
                                                                        textTransform: 'uppercase'
                                                                    }}>
                                                                        {capitalizeFLetter(row.name)}
                                                                    </div>
                                                                    <div style={{
                                                                        color: row.color
                                                                    }}
                                                                    >{row.value} USD
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </Div>
                                                ) : null;
                                            }}
                                            wrapperStyle={{
                                                background: 'rgba(255,255,255,.9)',
                                                borderRadius: 4,
                                                padding: '5px 8px',
                                                fontWeight: 500,
                                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                                            }}
                                        />
                                        <defs>
                                            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset={off} stopColor="#7352C7" stopOpacity={1} />
                                                <stop offset={off} stopColor="#F39711" stopOpacity={1} />
                                            </linearGradient>
                                        </defs>
                                        <Area type="monotone" dataKey="profit" stroke="#402C66" strokeWidth={2} fill="url(#splitColor)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Grid>
                            <Grid item xs={4} alignSelf={"center"}>
                                <Typography variant={"h2"}>$685k+</Typography>
                                <Typography variant={"body1"} color={"text.secondary"} sx={{ whiteSpace: 'nowrap' }}>Past 9 months</Typography>
                            </Grid>
                        </Grid>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <JumboCardQuick
                        title='Recent most active drivers'
                        wrapperSx={{ p: 0 }}
                        headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }}
                    >
                        <JumboScrollbar
                            autoHeight
                            autoHeightMin={366}
                            autoHide
                            autoHideDuration={200}
                            autoHideTimeout={500}
                        >
                            <List disablePadding>
                                {
                                    drivers.map((driver) => (
                                        <ListItemButton component={"li"} sx={{ p: theme => theme.spacing(1, 3), borderBottom: 1, borderBottomColor: 'divider' }}>
                                            <ListItemAvatar sx={{ minWidth: 64 }}>
                                                <Avatar alt={'driver profile'} src={driver.driver_id && driver.driver_id.profile_pic ? driver.driver_id.profile_pic : ''} sx={{ width: '52px', height: '52px', fontSize: '25px' }} >{driver.driver_id && driver.driver_id.name ? driver.driver_id.name[0] : ''}</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography variant='h5' mb={.5}>{driver.driver_id && driver.driver_id.name ? driver.driver_id.name : ''}</Typography>}
                                                secondary={driver.parked + " parked, " + driver.delivered + " delivered"}
                                            />
                                            <ListItemText sx={{ flexGrow: 0 }}>
                                                <Chip label={`${driver.driver_id && driver.driver_id.phone_no ? driver.driver_id.phone_no : ''}`} color="primary" size={'small'} />
                                            </ListItemText>
                                        </ListItemButton>
                                    ))
                                }
                            </List>
                        </JumboScrollbar>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <JumboCardQuick
                        title='Active trips'
                        wrapperSx={{ p: 0 }}
                        headerSx={{ borderBottom: 1, borderBottomColor: 'divider' }}
                    >
                        <JumboScrollbar
                            autoHeight
                            autoHeightMin={366}
                            autoHide
                            autoHideDuration={200}
                            autoHideTimeout={500}
                        >
                            <List disablePadding>
                                {
                                    trips.map((trip) => (
                                        <ListItemButton component={"li"} sx={{ p: theme => theme.spacing(1, 3), borderBottom: 1, borderBottomColor: 'divider' }}>
                                            <ListItemAvatar sx={{ minWidth: 64 }}>
                                                <Avatar alt={'car_logo'} src={trip.car_id && trip.car_id.logo_url ? trip.car_id.logo_url : trip.car_id && trip.car_id.brand ? `https://s3.ap-south-1.amazonaws.com/brand-logos/${trip.car_id.brand.replace(" ", "").toLowerCase()}.png` : ''} sx={{ width: '52px', height: '52px' }} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography variant='h5' mb={.5}>{trip.car_id ? `${trip.car_id.brand ? trip.car_id.brand : ''} ${trip.car_id.model ? trip.car_id.model : ''} ${trip.car_id.plate_no ? trip.car_id.plate_no : ''}` : ''}</Typography>}
                                                secondary={trip.car_id && trip.car_id.customer_id && trip.car_id.customer_id.name ? trip.car_id.customer_id.name : ''}
                                            />
                                            <ListItemText sx={{ flexGrow: 0 }}>
                                                <Chip label={`${capitalize(trip.status ? (trip.status === 'on_route' ? "en_route" : trip.status).replace("_", " ") : '')}`} color="primary" size={'small'} />
                                            </ListItemText>
                                        </ListItemButton>
                                    ))
                                }
                            </List>
                        </JumboScrollbar>
                    </JumboCardQuick>
                </Grid>
            </Grid>
        </>
    );
};

export default Dashboard;