import React, { useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../../config/main';
import DataTable from "../../shared/DataTable";
import './index.css';
import { CircularProgress, Button, ButtonGroup, Typography } from '@mui/material';
import AddStaff from "./AddStaff";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { toast } from "react-toastify";
import EditStaff from "./EditStaff";
import UpdateModules from "./UpdateModules";
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

const Staff = () => {
    const { showDialog, hideDialog } = useJumboDialog();
    const { userData } = useJumboAuth()
    const [loading, setLoading] = useState(false)
    const [staffData, setStaffData] = useState([])
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(1)
    const [searchedText, setSearchedText] = useState("")

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "phone",
            label: "Phone",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "role",
            label: "Role",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];
    console.log(tableData)
    const options = {
        searchPlaceholder: 'Press Enter to Search',
        filterType: 'dropdown',
        responsive: 'scroll',
        viewColumns: false,
        rowsPerPage: tableData.length || 100,
        page,
        rangePage: true,
        print: false,
        download: false,
        filter: false,
        search: true,
        sort: false,
        count: tableData.length,
        serverSide: true,
        server: true,
        selectableRowsOnClick: false,
        selectableRows: 'none',
        fixedHeader: false,
        searchText: searchedText,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    break;
                case 'changeRowsPerPage':
                    break;
                case 'search':
                    onkeypress = (e) => {
                        if (e.key == 'Enter') {
                            getStaff(tableState.searchText)
                            setSearchedText(tableState.searchText)
                        }
                    }
                    break;
                case 'onSearchClose':
                    setSearchedText("")
                    getStaff()
                    break;
                case 'onFilterDialogClose':
                    break
                case 'onFilterDialogOpen':
                    break;
                default:
                    break;
            }
        },
    }

    const getStaff = async (search) => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/staff?search=${search}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setStaffData(data.staffData || [])
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    const refreshData = () => {
        hideDialog()
        getStaff()
    }

    const deleteStaff = async (id) => {
        setLoading(true)
        try {
            const { data } = await axios.post(`${config.baseUrl}/staff/delete`, { id }, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                toast.success("Staff deleted successfully!")
                refreshData()
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    const handleAddClick = () => {
        showDialog({
            title: 'Add staff',
            content: <AddStaff refreshData={refreshData} />
        });
    }

    const handleEditClick = (data) => {
        showDialog({
            title: 'Edit staff',
            content: <EditStaff refreshData={refreshData} staff={data} />
        });
    }

    const handleDeleteClick = (data) => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure about deleting this staff?',
            content: "You won't be able to recover this staff later.",
            onYes: () => deleteStaff(data._id),
            onNo: hideDialog
        })
    }

    useEffect(() => {
        getStaff()
    }, [])

    const handleModulesClick = (data) => {
        showDialog({
            title: `Assign modules to staff "${data.name}"`,
            content: <UpdateModules refreshData={refreshData} staff={data} />,
        });
    }

    useEffect(() => {
        if (staffData && staffData.length > 0) {
            let data = []
            for (const val of staffData) {
                if (val) {
                    data.push({
                        name: val.name,
                        phone: val.phone_no,
                        role: val.role,
                        action: String(userData.id) !== String(val._id) && <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button variant="outlined" onClick={(e) => handleModulesClick(val)} className={'text-warning'}>
                                Modules
                            </Button>
                            <Button variant="outlined" onClick={(e) => handleEditClick(val)} className={'text-warning'}>
                                Edit
                            </Button>
                            <Button variant="outlined" onClick={(e) => handleDeleteClick(val)} className={'text-success'}>
                                Delete
                            </Button>
                        </ButtonGroup>
                    })
                }
            }
            setTableData(data)
        } else {
            setTableData([])
        }
    }, [staffData])

    return (
        <React.Fragment>
            <PageHeader
                title={<Typography fontSize={"30px"} variant="h6" color="text.primary" mb={.5}>Staff</Typography>}
            />
            {
                loading ?
                    <div className="loader-view" style={{ height: 'calc(100vh - 259px)' }}>
                        <CircularProgress />
                    </div>
                    :
                    <DataTable
                        title={<Button color='primary' variant="outlined" onClick={handleAddClick}> Add Staff +</Button>}
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
            }
        </React.Fragment>
    );
}

export default Staff;