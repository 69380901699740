import React from "react";
import Dashboard from "../pages/dashboard";
import Page from "@jumbo/shared/Page";
import Login from "../pages/login";
import VerifyOtp from "../pages/verifyOtp";
import { Navigate } from "react-router-dom";
import Customers from "app/pages/customers";
import Drivers from "app/pages/drivers";
import Shifts from "app/pages/shifts";
import Trips from "app/pages/trips/Trips";
import Staff from "app/pages/staff/Staff";
import DriverDetails from "app/pages/driverDetails/DriverDetails";
import TripDetails from "app/pages/tripDetails/TripDetails";
import Settings from "app/pages/settings/Settings";
import ActiveTrips from "app/pages/activeTrips/ActiveTrips";

const routesForPublic = [
    {
        path: "/login",
        element: <Page component={Login} layout={"solo-page"} />
    },
    {
        path: "/verify-otp",
        element: <Page component={VerifyOtp} layout={"solo-page"} />
    },
];

const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Navigate to={"/dashboard"} />
    },
    {
        path: "/dashboard",
        element: <Page component={Dashboard} layout={"vertical-default"} />
    },
    {
        path: "/customers",
        element: <Page component={Customers} layout={"vertical-default"} />
    },
    {
        path: "/drivers",
        element: <Page component={Drivers} layout={"vertical-default"} />
    },
    {
        path: "/shifts",
        element: <Page component={Shifts} layout={"vertical-default"} />
    },
    {
        path: "/trips",
        element: <Page component={Trips} layout={"vertical-default"} />
    },
    {
        path: "/staff",
        element: <Page component={Staff} layout={"vertical-default"} />
    },
    {
        path: "/drivers/details",
        element: <Page component={DriverDetails} layout={"vertical-default"} />
    },
    {
        path: "/trips/details",
        element: <Page component={TripDetails} layout={"vertical-default"} />
    },
    {
        path: "/settings",
        element: <Page component={Settings} layout={"vertical-default"} />
    },
    {
        path: "/active_trips",
        element: <Page component={ActiveTrips} layout={"vertical-default"} />
    },
];

const routesForNotAuthenticatedOnly = [];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };