import React, { useEffect, useState } from 'react';
import { CardHeader, Checkbox, ListItem, ListItemIcon, ListItemText, CircularProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { toast } from "react-toastify";
import axios from 'axios';
import { config } from '../../config/main';
import LoadingButton from "@mui/lab/LoadingButton";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const EnhancedTransferList = ({ refreshData, shift }) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [allDriversData, setAllDriversData] = useState([])
    const [loading, setLoading] = useState(false);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const assignDrivers = async () => {
        setSubmitting(true)
        try {
            const driver_ids = right.map(v => v._id)
            const { data } = await axios.post(`${config.baseUrl}/shifts/assign_drivers`, { driver_ids, id: shift._id }, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                refreshData()
                toast.success("Drivers assigned or removed from shift successfully")
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setSubmitting(false)
    }

    const getDrivers = async (search) => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/location_admin?search=${search}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setAllDriversData(data.driversData || [])
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (shift && shift._id) {
            getDrivers()
        }
    }, [shift])

    const getAssignedDrivers = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/shifts/assigned_drivers?id=${shift._id}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                const assignedDriverIds = (data.driversData || []).map(v => v._id)
                const filteredData = allDriversData.filter(v => !assignedDriverIds.includes(v._id))
                setLeft(filteredData)
                setRight(data.driversData || [])
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (allDriversData && allDriversData.length > 0) {
            getAssignedDrivers()
        }
    }, [allDriversData])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: 310,
                    height: 230,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.name} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <>
            {loading ?
                <div className="loader-view">
                    <CircularProgress />
                </div>
                :
                <>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>{customList('Available drivers', left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{customList('Selected drivers', right)}</Grid>
                    </Grid>
                    <LoadingButton
                        type="button"
                        onClick={assignDrivers}
                        variant="contained"
                        size="large"
                        sx={{ mt: 3 }}
                        loading={isSubmitting}
                    >Save</LoadingButton >
                </>
            }
        </>
    );
};

export default EnhancedTransferList;