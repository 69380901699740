import React, { useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../../config/main';
import DataTable from "../../shared/DataTable";
import './index.css';
import { CircularProgress, Button, ButtonGroup, Typography } from '@mui/material';
import { toast } from "react-toastify";
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';

const ActiveTrips = () => {
    const [loading, setLoading] = useState(false)
    const [tripsData, setTripsData] = useState([])
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(1)
    const [searchedText, setSearchedText] = useState("")

    const columns = [
        {
            name: "customer",
            label: "Customer",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "phone",
            label: "Phone",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "car",
            label: "Car",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "car_no",
            label: "Car No",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];

    const options = {
        searchPlaceholder: 'Press Enter to Search',
        filterType: 'dropdown',
        responsive: 'scroll',
        viewColumns: false,
        rowsPerPage: tableData.length,
        page,
        rangePage: true,
        print: false,
        download: false,
        filter: false,
        search: true,
        sort: false,
        count: tableData.length,
        serverSide: true,
        server: true,
        selectableRowsOnClick: false,
        selectableRows: 'none',
        fixedHeader: false,
        searchText: searchedText,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    break;
                case 'changeRowsPerPage':
                    break;
                case 'search':
                    onkeypress = (e) => {
                        if (e.key == 'Enter') {
                            getTrips(tableState.searchText)
                            setSearchedText(tableState.searchText)
                        }
                    }
                    break;
                case 'onSearchClose':
                    setSearchedText("")
                    getTrips()
                    break;
                case 'onFilterDialogClose':
                    break
                case 'onFilterDialogOpen':
                    break;
                default:
                    break;
            }
        },
    }

    const getTrips = async (search) => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/location_admin/active_trips?search=${search}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setTripsData(data.tripsData || [])
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    useEffect(() => {
        getTrips()
    }, [])

    useEffect(() => {
        if (tripsData && tripsData.length > 0) {
            let data = []
            for (const val of tripsData) {
                if (val) {
                    data.push({
                        customer: val.car_id && val.car_id.customer_id ? val.car_id.customer_id.name : '',
                        phone: val.car_id && val.car_id.customer_id ? val.car_id.customer_id.phone_no : '',
                        car: val.car_id ? val.car_id.brand : '',
                        car_no: val.car_id ? val.car_id.plate_no : '',
                        status: val.status ? (val.status === 'on_route' ? "en_route" : val.status).replace("_", " ").toUpperCase() : '',
                        action: <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <a href={`trips/details?id=${val._id}`}>
                                <Button variant="outlined" className={'text-warning'}>
                                    View
                                </Button>
                            </a>
                        </ButtonGroup>
                    })
                }
            }
            setTableData(data)
        } else {
            setTableData([])
        }
    }, [tripsData])

    return (
        <React.Fragment>
            <PageHeader
                title={<Typography fontSize={"30px"} variant="h6" color="text.primary" mb={.5}>Active trips</Typography>}
            />
            {
                loading ?
                    <div className="loader-view" style={{ height: 'calc(100vh - 259px)' }}>
                        <CircularProgress />
                    </div>
                    :
                    <DataTable
                        title=''
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
            }
        </React.Fragment>
    );
}

export default ActiveTrips;