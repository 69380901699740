import React from "react";
import HouseIcon from '@mui/icons-material/House';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import FlightIcon from '@mui/icons-material/Flight';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { Settings } from "@mui/icons-material";

const menus = [
    {
        uri: "/dashboard",
        label: 'Dashboard',
        type: "nav-item",
        icon: <HouseIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/customers",
        label: 'Customers',
        type: "nav-item",
        icon: <AccountBoxIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/shifts",
        label: 'Shifts',
        type: "nav-item",
        icon: <AccessTimeIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/drivers",
        label: 'Drivers',
        type: "nav-item",
        icon: <DriveEtaIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/trips",
        label: 'Trips',
        type: "nav-item",
        icon: <FlightIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/staff",
        label: 'Staff',
        type: "nav-item",
        icon: <BusinessCenterIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/settings",
        label: 'Settings',
        type: "nav-item",
        icon: <Settings sx={{ fontSize: 20 }} />
    },
    {
        uri: "/active_trips",
        label: 'Active trips',
        type: "nav-item",
        icon: <Settings sx={{ fontSize: 20 }} />
    },
];

export default menus;
