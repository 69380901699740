import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { config } from "../../../app/config/main";
import { routesForAuthenticatedOnly } from "../../../app/routes";

const initialState = {
    userData: {
        id: "",
        email: "",
        name: "",
        phone_no: "",
        location_id: "",
        is_staff: "",
        modules: "",
        profile_pic: "",
        location_title: "",
    },
    onLogout: async () => { },
    reloadUserData: async (credentials) => { },
    loading: false
};

const AuthContext = React.createContext(initialState);

const AuthProvider = ({ children }) => {
    const [userData, setUserData] = useState({
        id: "",
        email: "",
        name: "",
        phone_no: "",
        location_id: "",
        is_staff: "",
        modules: "",
        profile_pic: "",
        location_title: "",
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const onLogout = async () => {
        setUserData(initialState.userData);
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
    };

    const reloadUserData = async () => {
        try {
            const { data } = await axios.post(`${config.baseUrl}/location_admin/token/refresh`, null, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                localStorage.setItem("token", data.token);
                const payload = data.tokenData
                setUserData({
                    id: payload.admin_id,
                    name: payload.name,
                    email: payload.email,
                    phone_no: payload.phone_no,
                    location_id: payload.location_id,
                    is_staff: payload.is_staff,
                    modules: payload.modules,
                    profile_pic: payload.profile_pic,
                    location_title: payload.location_title,
                });
            }
            else {
                throw new Error(data.title);
            }
        } catch (e) {
            onLogout();
        }

        setLoading(false);
    };

    useEffect(() => {
        const verifyToken = async () => {
            setLoading(true);
            try {
                const { data } = await axios.post(`${config.baseUrl}/location_admin/token`, null, {
                    headers: { token: localStorage.getItem("token") }
                });
                if (!data.error) {
                    const payload = data.tokenData
                    setUserData({
                        id: payload.user_id,
                        name: payload.name,
                        email: payload.email,
                        phone_no: payload.phone_no,
                        location_id: payload.location_id,
                        is_staff: payload.is_staff,
                        modules: payload.modules,
                        profile_pic: payload.profile_pic,
                        location_title: payload.location_title,
                    });
                }
                else {
                    throw new Error(data.title);
                }
            } catch (e) {
                console.log('Error in auth', e)
                onLogout()
            }
            setLoading(false);
        };

        const authenticatedRouteOnly = routesForAuthenticatedOnly.filter(v => v.path == location.pathname)
        if (authenticatedRouteOnly && authenticatedRouteOnly[0]) {
            verifyToken();
        }
    }, [location]);

    useEffect(() => {
        const authenticatedRouteOnly = routesForAuthenticatedOnly.filter(v => v.path == location.pathname)
        if (authenticatedRouteOnly && authenticatedRouteOnly[0]) {
            if (location.pathname && location.pathname !== '/dashboard' && userData.is_staff) {
                const path = location.pathname.split('/');
                if (!userData.modules.includes(path[1])) {
                    onLogout()
                }
            }
        }
    }, [location, userData])

    const value = {
        userData,
        onLogout,
        reloadUserData,
        loading,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

export default AuthProvider
export { AuthProvider, AuthConsumer, AuthContext };