import React, { useState, useEffect } from 'react';
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import axios from 'axios';
import { config } from 'app/config/main';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const initialValues = {
    customers: false,
    shifts: false,
    drivers: false,
    trips: false,
    staff: false,
    settings: false,
};

const UpdateModules = ({ staff, refreshData }) => {
    const [staffData, setStaffData] = useState({})

    const onStaffSave = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const modules = []
            Object.keys(values).map(v => {
                if (values[v] == true) {
                    modules.push(v)
                }
            })
            const { data } = await axios.post(`${config.baseUrl}/staff/update_modules`, { modules, id: staff._id }, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                toast.success("Staff updated successfully!")
                refreshData()
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setSubmitting(false);
    };

    useEffect(() => {
        if (staff && Object.keys(staff).length > 0) {
            setStaffData({
                customers: staff && staff.modules ? staff.modules.includes('customers') : false,
                shifts: staff && staff.modules ? staff.modules.includes('shifts') : false,
                drivers: staff && staff.modules ? staff.modules.includes('drivers') : false,
                trips: staff && staff.modules ? staff.modules.includes('trips') : false,
                staff: staff && staff.modules ? staff.modules.includes('staff') : false,
                settings: staff && staff.modules ? staff.modules.includes('settings') : false,
            })
        } else {
            setStaffData(initialValues)
        }
    }, [staff])

    return (
        <Formik
            validateOnChange={true}
            initialValues={staffData}
            enableReinitialize={true}
            onSubmit={onStaffSave}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name='customers' checked={values.customers ? true : false} onChange={(e) => setFieldValue("customers", e.target.checked)} />} label="Customers" />
                            <FormControlLabel control={<Checkbox name='shifts' checked={values.shifts ? true : false} onChange={(e) => setFieldValue("shifts", e.target.checked)} />} label="Shifts" />
                            <FormControlLabel control={<Checkbox name='drivers' checked={values.drivers ? true : false} onChange={(e) => setFieldValue("drivers", e.target.checked)} />} label="Drivers" />
                            <FormControlLabel control={<Checkbox name='trips' checked={values.trips ? true : false} onChange={(e) => setFieldValue("trips", e.target.checked)} />} label="Trips" />
                            <FormControlLabel control={<Checkbox name='staff' checked={values.staff ? true : false} onChange={(e) => setFieldValue("staff", e.target.checked)} />} label="Staff" />
                            <FormControlLabel control={<Checkbox name='settings' checked={values.settings ? true : false} onChange={(e) => setFieldValue("settings", e.target.checked)} />} label="Settings" />
                        </FormGroup>
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >Save</LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};

export default UpdateModules;
