import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import axios from 'axios';
import { config } from 'app/config/main';
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import CropImage from './CropImage';

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Enter a valid email'),
    phone_no: yup.string().length(10, "Phone number must be 10 digit long").required('Phone number is required'),
});
const initialValues = {
    name: "",
    phone_no: "",
    email: "",
    profile_pic: "",
};

const EditProfile = ({ hideDialog }) => {
    const { userData, reloadUserData } = useJumboAuth();
    const [profile_pic, setProfileImg] = useState('')
    const [showCrop, setShowCrop] = useState(false)
    const [profileData, setProfileData] = useState(initialValues)

    useEffect(() => {
        setProfileImg(userData.profile_pic)
        setProfileData({
            name: userData.name || "",
            phone_no: userData.phone_no || "",
            email: userData.email || "",
            profile_pic: userData.profile_pic || "",
        })
    }, [userData])

    const onSubmitProfile = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            values.profile_pic = profile_pic
            const { data } = await axios.post(`${config.baseUrl}/location_admin/edit_profile`, values, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                toast.success("Profile updated successfully!")
                reloadUserData()
                hideDialog()
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setSubmitting(false);
    };

    const onSelectFile = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (files && files[0]) {
            const size = files[0].size / 1024
            if (size > 2048) {
                toast.error("Image size should be maximum 2 mb")
                return
            }
        }
        const reader = new FileReader();
        reader.onload = () => {
            setProfileImg(reader.result)
            setShowCrop(true)
        };
        if (files && files[0]) {
            reader.readAsDataURL(files[0]);
        }
    };

    return (
        <Formik
            validateOnChange={true}
            initialValues={profileData}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onSubmitProfile}
        >
            {({ isSubmitting, values, handleChange, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <div style={{ marginBottom: '24px' }}>
                            <input accept="image/*" id="profile" name='profile_pic' style={{ display: 'none' }} onChange={onSelectFile} type="file" />
                            <label htmlFor="profile">
                                <Avatar
                                    src={profile_pic}
                                    sx={{ boxShadow: 25, cursor: 'pointer', width: 80, height: 80 }}
                                />
                            </label>
                        </div>
                        {
                            showCrop &&
                            <CropImage setShowCrop={setShowCrop} image={profile_pic} setImage={setProfileImg} />
                        }
                        <JumboTextField fullWidth size="small" variant="outlined" name="phone_no" label="Phone Number" type="number" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="name" label="Name" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="email" label="Email" type="email" />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            disabled={showCrop}
                            loading={isSubmitting}
                        >Save</LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};

export default EditProfile;
