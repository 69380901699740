import React, { useState, useEffect } from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import axios from 'axios';
import { config } from 'app/config/main';
import { toast } from 'react-toastify';
import moment from 'moment';

const validationSchema = yup.object({
    title: yup.string().required('Title is required'),
    start_time: yup.string().required('Start time is required'),
    end_time: yup.string().required('End time is required'),
});
const initialValues = {
    title: "",
    start_time: "",
    end_time: "",
};

const EditShift = ({ shift, refreshData }) => {
    const [shiftData, setShiftData] = useState({})

    const onShiftSave = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            values.id = shift._id
            const { data } = await axios.post(`${config.baseUrl}/shifts/update`, values, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                toast.success("Shift updated successfully!")
                refreshData()
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setSubmitting(false);
    };

    useEffect(() => {
        if (shift && Object.keys(shift).length > 0) {
            setShiftData({
                title: shift.title || "",
                start_time: shift.start_time ? moment.utc(shift.start_time, "HH:mm").add(5, 'hours').add(30, 'minutes').format("HH:mm") : "",
                end_time: shift.end_time ? moment.utc(shift.end_time, "HH:mm").add(5, 'hours').add(30, 'minutes').format("HH:mm") : "",
            })
        } else {
            setShiftData(initialValues)
        }
    }, [shift])

    return (
        <Formik
            validateOnChange={true}
            initialValues={shiftData}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onShiftSave}
        >
            {({ isSubmitting }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <JumboTextField fullWidth size="small" variant="outlined" name="title" label="Title" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="start_time" label="Start time" type="time" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="end_time" label="End time" type="time" />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >Save</LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};

export default EditShift;
