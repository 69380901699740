import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import List from "@mui/material/List";
import { ListItem, ListItemText, Typography, ListItemIcon, CircularProgress } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@emotion/styled";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, Timeline } from "@mui/lab";
import moment from 'moment';
import PhoneIcon from '@mui/icons-material/Phone';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';
import { config } from '../../config/main';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));

const TripDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [timeline, setTimeline] = useState([])
    const [loading, setLoading] = useState(false)
    const [tripData, setTripData] = useState({})

    const getTripDetails = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/location_admin/trip_details?id=${id}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setTripData(data.tripData || {})
                setTimeline(data.tripData && data.tripData.timeline ? data.tripData.timeline : [])
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    useEffect(() => {
        getTripDetails()
    }, [id])

    const getTimeDifference = (date) => {
        const duration = moment.duration(moment().diff(date));
        if (duration.asSeconds() < 60) {
            return `${Math.floor(duration.asSeconds())} ${duration.asSeconds() <= 2 ? 'second' : 'seconds'} ago`;
        } else if (duration.asMinutes() < 60) {
            return `${Math.floor(duration.asMinutes())} ${duration.asMinutes() <= 2 ? 'minute' : 'minutes'} ago`;
        } else if (duration.asHours() < 24) {
            return `${Math.floor(duration.asHours())} ${duration.asHours() <= 2 ? 'hour' : 'hours'} ago`;
        } else {
            return `${Math.floor(duration.asDays())} ${duration.asDays() <= 2 ? 'day' : 'days'} ago`;
        }
    }

    return (
        <>
            <PageHeader
                title={<Typography fontSize={"30px"} variant="h6" color="text.primary" mb={.5}>Ticket details</Typography>}
            />
            {
                loading ?
                    <div className="loader-view" style={{ height: 'calc(100vh - 259px)' }}>
                        <CircularProgress />
                    </div>
                    :
                    <Grid container spacing={3.75}>
                        <Grid item xs={12} md={6}>
                            <JumboCardQuick divider title="Information" wrapperSx={{ backgroundColor: 'background.paper' }}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <List
                                            disablePadding
                                            sx={{
                                                margin: theme => theme.spacing(0, -2),
                                            }}
                                        >
                                            <ListItem>
                                                <StyledListItemIcon>
                                                    <ContactPageIcon fontSize={"inherit"} />
                                                </StyledListItemIcon>
                                                <ListItemText
                                                    primary={<Typography fontSize={"13px"} variant="h6" color="text.secondary" mb={.5}>Customer name</Typography>}
                                                    secondary={<Typography variant="body1" color="text.primary">{tripData && tripData.car_id && tripData.car_id.customer_id ? tripData.car_id.customer_id.name : ''}</Typography>}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <StyledListItemIcon>
                                                    <PhoneIcon fontSize={"inherit"} />
                                                </StyledListItemIcon>
                                                <ListItemText
                                                    primary={<Typography fontSize={"13px"} variant="h6" color="text.secondary" mb={.5}>Phone number</Typography>}
                                                    secondary={<Typography variant="body1" color="text.primary">{tripData && tripData.car_id && tripData.car_id.customer_id ? tripData.car_id.customer_id.phone_no : ''}</Typography>}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <StyledListItemIcon>
                                                    <DirectionsCarIcon fontSize={"inherit"} />
                                                </StyledListItemIcon>
                                                <ListItemText
                                                    primary={<Typography fontSize={"13px"} variant="h6" color="text.secondary" mb={.5}>Car name</Typography>}
                                                    secondary={<Typography variant="body1" color="text.primary">{tripData && tripData.car_id ? (tripData.car_id.brand + " " + tripData.car_id.model) : ''}</Typography>}
                                                />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <List
                                            disablePadding
                                            sx={{
                                                margin: theme => theme.spacing(0, -2),
                                            }}
                                        >
                                            <ListItem>
                                                <StyledListItemIcon>
                                                    <DirectionsCarIcon fontSize={"inherit"} />
                                                </StyledListItemIcon>
                                                <ListItemText
                                                    primary={<Typography fontSize={"13px"} variant="h6" color="text.secondary" mb={.5}>Car number</Typography>}
                                                    secondary={<Typography variant="body1" color="text.primary">{tripData && tripData.car_id ? tripData.car_id.plate_no : ''}</Typography>}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <StyledListItemIcon>
                                                    <ConfirmationNumberIcon fontSize={"inherit"} />
                                                </StyledListItemIcon>
                                                <ListItemText
                                                    primary={<Typography fontSize={"13px"} variant="h6" color="text.secondary" mb={.5}>Ticket number</Typography>}
                                                    secondary={<Typography variant="body1" color="text.primary">{tripData ? tripData.ticket_no : ''}</Typography>}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <StyledListItemIcon>
                                                    <TimelineIcon fontSize={"inherit"} />
                                                </StyledListItemIcon>
                                                <ListItemText
                                                    primary={<Typography fontSize={"13px"} variant="h6" color="text.secondary" mb={.5}>Status</Typography>}
                                                    secondary={<Typography variant="body1" color="text.primary">{tripData && tripData.status ? tripData.status.replace("_", " ").toUpperCase() : ''}</Typography>}
                                                />
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </JumboCardQuick>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <JumboCardQuick divider title="Timeline" wrapperSx={{ backgroundColor: 'background.paper' }}>
                                <Timeline sx={{ mt: 0 }}>
                                    {
                                        timeline.map((item) => (
                                            <TimelineItem
                                                sx={{
                                                    '&::before': {
                                                        display: 'none'
                                                    }
                                                }}
                                            >
                                                <TimelineSeparator>
                                                    <TimelineDot sx={{
                                                        m: theme => theme.spacing(.5, 0),
                                                        bgcolor: item.status === 'parked' ? '#18d87b' : '#2a5294',
                                                        width: 20,
                                                        height: 20,
                                                        boxShadow: '0 0 0 4px #fff,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05)',
                                                    }}
                                                    >
                                                        {item.icon}
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent
                                                    sx={{
                                                        p: theme => theme.spacing(.5, 0),
                                                        ml: 2.5
                                                    }}
                                                >
                                                    <Typography variant="h5" mb={1}>{item.status ? (item.status === 'on_route' ? 'en_route' : item.status).replace("_", " ").toUpperCase() : ''} - {item.driver_id ? item.driver_id.name : ''}</Typography>
                                                    <Typography variant={"h6"} color={"text.secondary"}>{getTimeDifference(moment(item.date_time))}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))
                                    }
                                </Timeline>
                            </JumboCardQuick>
                        </Grid>
                    </Grid>
            }
        </>
    )
};

export default TripDetails;
