import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import { Typography, CircularProgress, Avatar } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { toast } from "react-toastify";
import axios from 'axios';
import { config } from '../../config/main';
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import CropImage from 'app/shared/widgets/AuthUserDropdown/CropImage';
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

const validationSchema = yup.object({
    eta: yup.number().min(1, "ETA should be greater than 0").required('Enter a valid standard ETA')
});
const initialValues = {
    charge: "",
    eta: "",
    image: "",
};

const Settings = () => {
    const { showDialog, hideDialog } = useJumboDialog();
    const [loading, setLoading] = useState(false)
    const [settingsData, setSettingsData] = useState(initialValues)
    const [showCrop, setShowCrop] = useState(false)
    const [image, setImage] = useState('')

    const getSettings = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/locations/settings`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setImage(data.locationDetails.image)
                setSettingsData({
                    charge: data.locationDetails.charge || "",
                    eta: data.locationDetails.eta || "",
                    image: data.locationDetails.image || "",
                })
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    useEffect(() => {
        getSettings()
    }, [])

    const onSubmitSettings = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            values.image = image
            const { data } = await axios.post(`${config.baseUrl}/locations/update_settings`, values, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                hideDialog()
                getSettings()
                toast.success("Settings updated successfully!")
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setSubmitting(false);
    };

    const onSelectFile = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (files && files[0]) {
            const size = files[0].size / 1024
            if (size > 2048) {
                toast.error("Image size should be maximum 2 mb")
                return
            }
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result)
            setShowCrop(true)
        };
        if (files && files[0]) {
            reader.readAsDataURL(files[0]);
        }
    };

    const handleSaveClick = (values, { setSubmitting }) => {
        showDialog({
            variant: 'confirm',
            title: 'Update settings confirmation',
            content: "Are you sure you want to update settings?",
            onYes: () => onSubmitSettings(values, { setSubmitting }),
            onNo: hideDialog
        })
    }

    return (
        <>
            <PageHeader
                title={<Typography fontSize={"30px"} variant="h6" color="text.primary" mb={.5}>Settings</Typography>}
            />
            {
                loading ?
                    <div className="loader-view" style={{ height: 'calc(100vh - 259px)' }}>
                        <CircularProgress />
                    </div>
                    :
                    <Grid container spacing={3.75}>
                        <Grid item xs={12}>
                            <JumboCardQuick divider title="" wrapperSx={{ backgroundColor: 'background.paper' }} sx={{ minHeight: '65vh' }}>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Formik
                                            validateOnChange={true}
                                            initialValues={settingsData}
                                            enableReinitialize={true}
                                            validationSchema={validationSchema}
                                            onSubmit={handleSaveClick}
                                        >
                                            {({ isSubmitting }) => (
                                                <Form noValidate autoComplete="off">
                                                    <Div
                                                        sx={{
                                                            '& .MuiTextField-root': {
                                                                mb: 3
                                                            },
                                                        }}
                                                    >
                                                        <div style={{ marginBottom: '24px', width: 360, height: 240 }}>
                                                            <input accept="image/*" id="location_ing" name='image' style={{ display: 'none' }} onChange={onSelectFile} type="file" />
                                                            <label htmlFor="location_ing">
                                                                <Avatar
                                                                    variant='rounded'
                                                                    src={image}
                                                                    sx={{ boxShadow: 25, cursor: 'pointer', width: 360, height: 240 }}
                                                                />
                                                            </label>
                                                        </div>
                                                        {
                                                            showCrop &&
                                                            <CropImage setShowCrop={setShowCrop} image={image} setImage={setImage} ratio={3 / 2} />
                                                        }
                                                        <JumboTextField fullWidth size="small" variant="outlined" name="charge" label="Parking fee" type="number" />
                                                        <JumboTextField fullWidth size="small" variant="outlined" name="eta" label="Standard ETA" type="number" />
                                                        <LoadingButton
                                                            type="submit"
                                                            variant="contained"
                                                            size="large"
                                                            sx={{ mb: 3 }}
                                                            disabled={showCrop}
                                                            loading={isSubmitting}
                                                        >SUBMIT</LoadingButton>
                                                    </Div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Grid>
                                </Grid>
                            </JumboCardQuick>
                        </Grid>
                    </Grid>
            }
        </>
    )
};

export default Settings;
