import React, { useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../../config/main';
import DataTable from "../../shared/DataTable";
import './index.css';
import { Button, CircularProgress, ButtonGroup, Typography } from "@mui/material";
import AddDriver from "./AddDriver";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { toast } from "react-toastify";
import EditDriver from "./EditDriver";
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Drivers = () => {
    const { showDialog, hideDialog } = useJumboDialog();
    const [loading, setLoading] = useState(false)
    const [driversData, setDriversData] = useState([])
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(1)
    const [searchedText, setSearchedText] = useState("")

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "phone",
            label: "Phone",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "manager",
            label: "Manager",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];

    const options = {
        searchPlaceholder: 'Press Enter to Search',
        filterType: 'dropdown',
        responsive: 'scroll',
        viewColumns: false,
        rowsPerPage: tableData.length,
        page,
        rangePage: true,
        print: false,
        download: false,
        filter: false,
        search: true,
        sort: false,
        count: tableData.length,
        serverSide: true,
        server: true,
        selectableRowsOnClick: false,
        selectableRows: 'none',
        fixedHeader: false,
        searchText: searchedText,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    break;
                case 'changeRowsPerPage':
                    break;
                case 'search':
                    onkeypress = (e) => {
                        if (e.key == 'Enter') {
                            getDrivers(tableState.searchText)
                            setSearchedText(tableState.searchText)
                        }
                    }
                    break;
                case 'onSearchClose':
                    setSearchedText("")
                    getDrivers()
                    break;
                case 'onFilterDialogClose':
                    break
                case 'onFilterDialogOpen':
                    break;
                default:
                    break;
            }
        },
    }

    const getDrivers = async (search) => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/location_admin/drivers_and_managers?search=${search}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setDriversData(data.driversData || [])
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    const refreshData = () => {
        hideDialog()
        getDrivers()
    }

    const deleteDriver = async (id) => {
        setLoading(true)
        try {
            const { data } = await axios.post(`${config.baseUrl}/location_admin/delete`, { id }, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                toast.success("Driver deleted successfully!")
                refreshData()
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    const handleAddClick = () => {
        showDialog({
            title: 'Add driver',
            content: <AddDriver refreshData={refreshData} />
        });
    }

    const handleEditClick = (data) => {
        showDialog({
            title: 'Edit driver',
            content: <EditDriver refreshData={refreshData} driver={data} />
        });
    }

    const handleDeleteClick = (data) => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure about deleting this driver?',
            content: "You won't be able to recover this driver later.",
            onYes: () => deleteDriver(data._id),
            onNo: hideDialog
        })
    }

    useEffect(() => {
        getDrivers()
    }, [])

    useEffect(() => {
        if (driversData && driversData.length > 0) {
            let data = []
            for (const val of driversData) {
                data.push({
                    name: <div style={{ display: 'flex', justifyContent: 'start' }}>
                        <div>
                            {val.name}
                        </div>
                        {
                            !val.is_manager &&
                            <div style={{ marginTop: '2px', marginLeft: '5px' }}>
                                <FiberManualRecordIcon fontSize="1rem" color={val.online_shift_id ? 'success' : 'error'} />
                            </div>
                        }
                    </div>,
                    phone: val.phone_no,
                    manager: val.is_manager ? 'Yes' : 'No',
                    action: <ButtonGroup color="primary" aria-label="outlined primary button group">
                        {
                            !val.is_manager &&
                            <a href={`drivers/details?id=${val._id}`}>
                                <Button variant="outlined" className={'text-warning'} sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightColor: 'transparent' }}>
                                    View
                                </Button>
                            </a>
                        }
                        <Button variant="outlined" onClick={(e) => handleEditClick(val)} className={'text-warning'} sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                            Edit
                        </Button>
                        <Button variant="outlined" onClick={(e) => handleDeleteClick(val)} className={'text-success'}>
                            Delete
                        </Button>
                    </ButtonGroup>
                })
            }
            setTableData(data)
        } else {
            setTableData([])
        }
    }, [driversData])

    return (
        <React.Fragment>
            <PageHeader
                title={<Typography fontSize={"30px"} variant="h6" color="text.primary" mb={.5}>Drivers</Typography>}
            />
            {
                loading ?
                    <div className="loader-view" style={{ height: 'calc(100vh - 259px)' }}>
                        <CircularProgress />
                    </div>
                    :
                    <DataTable
                        title={<Button color='primary' variant="outlined" onClick={handleAddClick}> Add Driver +</Button>}
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
            }
        </React.Fragment>
    );
}

export default Drivers;